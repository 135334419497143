@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Impact', 'Arial Narrow Bold',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Bebas Neue', sans-serif 'Veranda';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 66px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  /* Make the navbar fixed */
  top: 0;
  /* Stick it to the top */
  width: 100%;
  /* Full width */
  z-index: 1030;
  /* Ensure it's above other elements */
  background-color: #fff;
  /* Default background color */
  transition: all 0.3s ease-in-out;
  /* Smooth transition for animations */
}

.sub-navbar {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  /* Make the navbar fixed */
  top: 2;
  /* Stick it to the top */
  width: 100%;
  /* Full width */
  z-index: 1030;
  transition: all 0.3s ease-in-out;
  /* Smooth transition for animations */

}

.nav-link {
  color: #847aff;
}

.navbar-brand {
  color: white;
  margin-left: 30px;
}

.smallimg {
  height: Auto;
  width: 100% !important;
  border-radius: 5px !important;
}

h1 {
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.btn {
  background-color: #434343;
  color: white;
  box-shadow: none !important;
}

.bs {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  margin-top: 8px !important;
  padding: 20px !important;
  background-color: #ffffffd5;
}

.bigimg {
  height: auto;
  width: auto;
  border: 5px;
}

.booking-card {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  /* Space between text and image */
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: #fff;
}

.card-body h1,
.card-body h2 {
  margin-bottom: 1.5rem;
  /* Reduce bottom margin for headings */
}

.card-body p {
  margin-bottom: 0.25rem;
  /* Reduce bottom margin for paragraphs */
  line-height: 1.4;
  /* Adjust line height for better readability */
}

/* Additional styling to ensure text is not too close to other elements */
.card-body {
  padding: 1rem;
  /* Adjust padding as needed */
}

.border {
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* Customize border color and style */
}

.rounded {
  border-radius: 0.25rem;
  /* Customize border radius */
}

.img-fluid {
  border: 1px solid #ddd;
  /* Add border around the image if needed */
  border-radius: 0.25rem;
  /* Match the image border radius with card */
}

input.form-control,
select.form-control,
.ant-picker {
  box-shadow: none !important;
  border: 1px solid #433d80;
  width: 100%;
  margin-top: 10px;
}

.navbar-nav {
  margin-right: 30px;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0);
}

.navbar-toggler .bi-list,
.navbar-toggler-icon,
.navbar-toggler::before {
  color: #433d80;
  /* Set the icon color */
  font-size: 1.5rem;
  /* Adjust the size if necessary */
}


.ant-picker {
  width: 100%;
  border-radius: 0.25rem;
  border-color: white;
  /* Match the border radius with other inputs */
}

/* If needed, you can also adjust the padding */
.ant-picker-input {
  padding: 0.175rem;
  /* Adjust as needed for consistent padding */
}

.landing {
  background-color: #4d4692;
  height: 100vh;
  /* Ensures full viewport height */
  display: flex;
  /* Centers content vertically */
  justify-content: center;
  /* Centers content horizontally */
  padding: auto;
  /* Remove any extra padding */
  margin: 0;
  /* Remove any extra margin */
}

.landingbtn {
  color: black;
  background-color: white;
  border-radius: 5px;
}

.room-table {
  width: 100%;
  margin-top: 20px;
}

/* Individual cell style for availability */
.availability-cell {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for availability cells */
.availability-cell:hover {
  background-color: #f0f0f0;
  /* Light gray on hover */
}

/* Selected cell style */
.availability-cell.selected {
  background-color: #1890ff;
  /* Ant Design blue for selected */
  color: white;
  /* White text for contrast */
}

.table-container {
  width: 100%;
  max-width: 100%;
  /* Set a fixed width to show a certain number of dates */
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow-x: auto;
  /* Allows horizontal scrolling */
  white-space: nowrap;
  /* Prevents cells from wrapping */
  border: 1px solid #ddd;
  background-color: #fff;
}

.table-container th,
.table-container td {
  border: 0.5px solid #965ea3;
  text-align: center;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
}

.table-container th {
  background-color: #d9caff;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
  /* Ensure it's above other columns */
  box-shadow: 2px 0 5px -2px #888;
  /* Optional: Add shadow for better distinction */
}

.sticky-column::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  /* Align the shadow on the right edge of the column */
  width: 3px;
  /* Adjust width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensures the shadow effect doesn't overlap other content */
}

.sticky-room-name {
  position: sticky;
  display: block;
  left: 0;
  background-color: #fff !important;
  /* Dark background for room name */
  color: #433d80;
  /* White text */
  font-size: 25px;
  /* Larger font size */
  z-index: 3;
  /* Ensure it stays on top of other elements */
  padding: 0px 5px !important;
  font-family: 'Bebas Neue', sans-serif;

  /* Optional shadow for better distinction */
}

.sticky-top {
  position: sticky;
  left: 0;
  background-color: #d9caff;
  z-index: 1;
}

.table-container tr:nth-child(odd) td.sticky-column {
  background-color: #ececec;
  /* Light grey for odd rows */
}

.table-container tr:nth-child(even) td.sticky-column {
  background-color: #ffffff;
  /* White for even rows */
}

.table-container td:hover {
  background-color: #8bc4fc !important;
  /* Light green background on hover */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}

.table-container th:hover {
  background-color: #8bc4fc;
  /* Light green background on hover */
  /* Change cursor to pointer on hover */
}

.table-container tr:nth-child(odd) {
  background-color: #ececec;
  /* Apply interleaved color to odd rows, except the first cell (Room Name) */
}

.table-container tr:nth-child(even) {
  background-color: #ffffff;
  /* Apply white color to even rows, except the first cell (Room Name) */
}

@media (max-width: 600px) {

  .table-container th,
  .table-container td {
    font-size: 10px;
    padding: 5px 6px !important;
    /* Smaller text on small screens */
  }

  h1 {
    font-size: 1.8rem;
    /* Smaller font size for mobile */
  }

  .sticky-room-name {
    position: sticky;
    display: block;
    left: 0;
    background-color: #fff !important;
    /* Dark background for room name */
    color: #433d80;
    /* White text */
    font-size: 15px;
    /* Larger font size */
    z-index: 3;
    /* Ensure it stays on top of other elements */
    padding: 0px 5px !important;
    font-family: 'Bebas Neue', sans-serif;

    /* Optional shadow for better distinction */
  }

  .availability-cell {
    font-size: 10px !important;
    padding: 3px !important;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .availability-box {
    font-size: 10px !important;
  }

  .calendar-container {
    display: flex;
    gap: 5px;
  }

  .calendar-container .button-select:nth-child(1),
  .calendar-container .button-select:nth-child(2) {
    width: 40%;
    font-size: 12px !important;
    /* Each button takes half the row */
    justify-content: center;
  }

  .calendar-container .month-year-picker {
    width: 90%;
    /* Fit all three elements side-by-side */
    text-align: center;
  }

  .calendar-container .button-select:nth-child(3),
  .calendar-container .button-select:nth-child(5) {
    width: 12%;
    /* Fit all three elements side-by-side */
    text-align: center;
    margin-left: 10px;

  }

}

.room-divider {
  height: 15px;
  background-color: #ffffff;
  /* Light grey color for the divider */
}

.room-divider td {
  border-bottom: 2px solid #d9caff;
  /* Adds a solid line as a divider */
  height: 20px;
  /* Adjust height as needed */
}


.button-select {
  padding: 10px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: #1c2389;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.month-year-picker {
  width: 160px;
  /* Adjust width based on the appearance you want */
}


.button-select:hover {
  background-color: #1c2389;
}

.calendar-container {
  display: flex;
  justify-content: flex-start;
  /* Aligns all items to the right */
  align-items: center;
  /* Vertically aligns buttons and inputs */
  gap: 5px;
  z-index: 1 !important;
  /* Adds spacing between elements */
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.month-year-picker {
  padding: 4px 15px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #1c2389;
  font-size: 16px;
}

.month-year-picker {
  width: 100px;
  /* Adjust width to fit the design */
}


.month-year-picker {
  width: 100%;
}

.weekend-highlight {
  background-color: #d9caff !important;
  /* Light blue for weekends */
  color: #9e7305;
  /* Adjust text color if needed */
  font-weight: bold;
  /* Make text bold */
}


.table-container::-webkit-scrollbar {
  height: 8px;
  /* Height of the scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #1c2389;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners for the scrollbar */
}

.table-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Color of the scrollbar track */
}

.availability-box {
  display: inline-block;
  padding: 4px 8px;
  background-color: #ffffff;
  /* White background */
  border: 1px solid #ccc;
  /* Light grey border */
  border-radius: 4px;
  /* Slightly rounded corners */
  font-size: 14px;
  /* Adjust font size as needed */
}

.derived-rate {
  opacity: 0.6;
  /* Lower opacity for fading effect */
  font-style: italic;
  font-size: 0.9em;
  /* Italic text for visual distinction */
}

.derived-rate .link-symbol {
  margin-left: 4px;
  /* Space between the rate text and the symbol */
  vertical-align: middle;
  /* Align the symbol vertically with the text */
  font-size: 0.7em;
  /* Slightly smaller symbol size, adjust as needed */
}

.booking-sidebar-global {
  margin-top: 50px;
  position: fixed;
  top: 48px;
  right: 20px;
  width: 300px;
  line-height: 0.6;
  z-index: 1600;
  font-size: 15px;
  max-height: 60vh;
  /* Limit the height to enable scrolling */
  overflow-y: auto;
  background: linear-gradient(135deg, rgba(150, 95, 190, 0.645), rgba(78, 43, 87, 0.175));
  /* Gradient background */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  transition: all 0.1s ease;
}

.booking-sidebar-content {
  padding-bottom: 5px;
  line-height: 0.2;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 1600;
  /* Add padding to avoid overlap with the button */
}

.booking-sidebar-global .drag-handle .no-drag {
  pointer-events: auto;
}

.drag-handle {
  background-color: #443d80;
  /* Match branding */
  padding: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  z-index: 1500;
  cursor: pointer;
}

.drag-handle p {
  margin: 0;
  /* Remove default margins for tighter spacing */
  font-size: 12px;
  /* Adjust font size for mobile */
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  /* Match the sidebar color */
  z-index: 10;
  /* Keep it above other elements in the sidebar */
}

@media (max-width: 768px) {
  .booking-sidebar-global {
    position: sticky;
    top: 1.5%;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 52%;
    overflow-y: auto;
    border-radius: 0;
    background: linear-gradient(135deg, rgba(150, 95, 190, 0.645), rgba(78, 43, 87, 0.175));
  }

  .drag-handle {
    flex-direction: row;
    /* Stack items vertically */
    align-items: flex-start;
    padding: 20px;
    /* Add some padding for spacing */
  }
}

.rate-plan-summary {
  margin-bottom: 5px;
  line-height: 0.3;
  /* Smaller line spacing */
}

.searchbar {

  /* Gradient background */
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

/* For Payment Method Screen*/

.payment-method-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 600px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-method-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
}

.payment-options {
  display: flex;
  flex-direction: column;
}

.payment-option {
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.payment-option:hover {
  background-color: #f0f0f0;
}

.payment-option.selected {
  border-color: green;
  background-color: #e6ffed;
}

.closed-out {
  background-color: #f44336;
  color: white;
  cursor: not-allowed;
}

.container {
  /* Semi-transparent background */
  padding: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
}

.searchbar-glass:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(12px);
}

.apply-button {
  transition: transform 0.2s ease;
}

.apply-button:hover {
  transform: scale(1.1);
}

/* Further reduce the heading size on smaller screens */
@media (max-width: 768px) {
  .room-info h1 {
    font-size: 2rem;
  }

  .room-info p {
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  .room-info p svg {
    font-size: 1rem;
    /* Adjust icon size to match smaller text */
    margin-right: 5px;
    /* Spacing between icon and text */
  }

  .apply-button {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .rate-info .rate-pricing {
    display: inline;
    /* Stack originalRate and totalRate vertically */
    align-items: end;
  }

  /* Styling for originalRate */
  .original-rate {
    font-size: 0.8rem !important;
    /* Smaller font size */
    text-decoration: line-through;
    /* Space between originalRate and totalRate */
  }

  /* Styling for totalRate */
  .total-rate {
    font-size: 1.1rem !important;
    /* Slightly larger font for emphasis */
    font-weight: bold;
  }

  .ant-tag {
    font-size: 0.6rem;
    /* Smaller font for the tag *
    /* Adjust padding for a more compact look */
    /* Space between rate plan name and tag */
  }

  .pick-button {
    font-size: 10px;
    /* Smaller font for mobile */
    padding: 3px 7px;
    margin-left: auto;
    margin-top: 5px;
  }

  .meal-cancel {
    font-size: 0.6rem !important;
  }

  .add-on {
    font-size: 0.9rem !important;
  }

  .add-on-info {
    font-size: 0.7rem !important;
  }

  .add-on-price {
    font-size: 1rem !important;
  }

}

/* Admin Screen Css */
.responsive-menu {
  display: flex;
  gap: 10px;
  overflow: hidden;
}

/* Make dropdowns responsive on smaller screens */
@media (max-width: 768px) {
  .ant-menu-submenu-popup {
    width: 90vw !important;
    /* Limit width to 90% of the viewport */
    left: 5vw !important;
    /* Center horizontally */
    overflow-x: auto;
    /* Enable horizontal scrolling if needed */
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling on iOS */
  }

  /* Make dropdown text smaller */
  .ant-menu-item,
  .ant-menu-submenu-title {
    font-size: 11px !important;
    /* Smaller font size for mobile */
  }

  /* Hide dropdown scrollbar for a cleaner look */
  .ant-menu-submenu-popup::-webkit-scrollbar {
    display: none;
  }

  .responsive-menu {
    width: 100%;
    font-size: 1px !important;
    /* Reduce font size on mobile */
    padding: 0 10px;
    /* Reduce padding */
    gap: 5px;
    /* Reduce space between items */
  }

  .responsive-menu .ant-menu-item,
  .responsive-menu .ant-menu-submenu-title {
    padding: 0 4px;
    /* Reduce padding within each menu item */
  }

  .responsive-menu,
  .ant-layout-header {
    padding: 0 !important;
    margin: 0 !important;
  }

}

/* Body Language and Currency Dropdown */
@media (max-width: 768px) {
  .sticky-dropdowns {
    display: none !important;
  }
}

.confirmationContainer {
  width: 100%;
  padding: 20px;
  background: #fff;
  overflow: visible;
  /* Prevent clipping */
  box-sizing: border-box;
}